<template>
  <div class="home">
    <!-- 菜单栏 -->
    <div class="menulist" ref="menu">
      <div>
        <!-- <img v-if="!isCollapse" src="../assets/home/big-logo.png" alt="" class="bigLogo" @click="toIndex" /> -->
        <div  v-if="!isCollapse" class="bigIMG">
          <img src="../assets/home/BX-yellow.png" alt="">
          <span>白象电器</span>
        </div>
        <img v-else src="../assets/home/BX-yellow.png" class="smallLogo" alt="" @click="toIndex" />
      </div>
      <el-menu unique-opened :default-active="$route.path" class="el-menu-vertical-demo" :collapse="isCollapse"
        active-text-color="#FFF" text-color="#FFF">
        <template v-for="item in menuList">
          <el-menu-item :key="item.path" :index="item.path + ''"
            @click="routerUrl(item.path)">
            <!-- <i class="el-icon-location"></i> -->
            <img :src="item.icon" alt="" style="width: 24px" />
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
          <!-- <el-submenu v-else :key="item.id" :index="item.id + ''">
            <template slot="title">
              <img :src="item.iconUrl" alt="" style="width: 24px" />
              <span slot="title">{{ item.permissionName }}</span>
            </template>
            <el-menu-item-group>
              <template v-for="child in item.children">
                <el-menu-item class="menu-item-group" :key="child.id" :index="child.frontPath + ''"
                  @click="routerUrl(child.frontPath)">
                  <span style="margin-left: 25px">{{
                    child.permissionName
                  }}</span></el-menu-item>
              </template>
            </el-menu-item-group>
          </el-submenu> -->
        </template>
      </el-menu>
    </div>
    <div style="width: 100%; overflow: hidden; background: #eee">
      <!-- 头部 -->
      <div class="header">
        <div class="control">
          <div @click="isCollapse = !isCollapse">
            <i v-if="isCollapse" class="el-icon-s-unfold"></i>
            <i v-else class="el-icon-s-fold"></i>
          </div>
          
        </div>

        <div class="avatar">
          <el-popover placement="top" width="160" v-model="visible" trigger="hover">
            <div class="logout" style="cursor: pointer" @click="logOpen()">
              退出登录
            </div>
            <img src="../assets/home/avatar.jpeg" alt="" slot="reference" />
          </el-popover>
          <span>{{ account }}</span>
        </div>
      </div>
      <div class="view">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from 'axios';
import deviceImg from "../assets/home/device.png";
import smartImg from "../assets/home/smart.png";
import alarmImg from "../assets/home/alarm.png";
import systemImg from "../assets/home/system.png";
export default {
  data() {
    return {
      projectName: "字景物联网项目",
      // 菜单列表是否展开
      isCollapse: false,
      visible: false,
      // 菜单列表
      menuList: [
        {
          title: "产品管理",
          flag: true,
          id: "1",
          icon: deviceImg,
          path: "/deviceInfo",
        },
        {
          title: "合作案例管理",
          flag: true,
          id: " 2",
          icon: smartImg,
          path: "/collaborative",
        },
        {
          title: "新闻管理",
          flag: true,
          id: "3",
          icon: alarmImg,
          path: "/news",
        },
      ],
      value: "",
      account: localStorage.getItem("account"),
    };
  },
  
  mounted() {
    // 请求菜单栏
    // this.menulistRequest();
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      var that = this;
      console.log(to.path, from.path);
    
      if(to.path=='/3DShow'){
        console.log('11')
        location.reload();
      }
      
    },
  },

  methods: {
    // 刷新页面
    refresh() {
      location.reload();
      // this.$router.go(0)
    },
    // 跳转页面
    routerUrl(url) {
      // console.log(url);
      this.$router.push(url);
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    
    // 跳转首页
    toIndex() {
      this.$router.push("/index");
    },
    // 打开退出登录
    logOpen() {
      this.$confirm("确定要退出登录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warnimg",
      })
        .then((status) => {
          if (status == "confirm") {
            this.logout();
          }
        })
        .catch((status) => {
          if (status == "cancel") {
            this.cancelLogout();
          }
        });
    },
    // 退出登录
    logout() {
      //   window.sessionStorage.clear();
      //   this.clearCookie();
      this.$get("/manage/user/logout")
        .then((res) => {
          if (res.code == 1) {
            this.$router.push("/Login");
            this.$message({
              type: "success",
              message: "登出成功!",
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    cancelLogout() {
      this.visible = false;
      this.$message({
        type: "info",
        message: "已取消登出",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-icon-s-unfold {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: -5px;
}

.el-icon-s-fold {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: -10px;
}

.home {
  width: 100%;
  display: flex;
  background-color: rgb(29, 68, 128);

  /* 头部 */
  .header {
    background-color: #fff;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(0, 0, 0, 0.45);

    .control {
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-top: 5px;

      .title {
        margin: -8px 0 0 10px;
      }

      .select-project {
        width: 150px;
        margin-left: 20px;
        margin-top: -5px;

        li.el-select-dropdown__item {
          color: #606266 !important;
        }
      }
    }

    .avatar {
      display: flex;
      align-items: center;
      img{
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
      span {
        vertical-align: middle;
        margin: 5px 10px 0 10px;
      }
    }

    // 退出登录
    .logout:hover {
      background-color: #eee;
      width: 100%;
      height: 100%;
    }
  }
}
// 侧边栏大图标
.bigIMG{
  img{
    width: 6vw;
    vertical-align: middle;
  }
  span{
    vertical-align: middle;
    color: #fff;
    font-weight: 700;
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 210px;
}

.menulist {
  height: 100vh;
  background-color: rgb(29, 68, 128);
  box-sizing: border-box;
  text-align: center;
  padding-top: 23px;
  box-sizing: border-box;

  .bigLogo {
    width: 125px;
  }

  .smallLogo {
    height: 40px;
  }
}
</style>
<style>
/* element-ui菜单样式修改 */
.el-menu {
  background-color: rgb(29, 68, 128);
  border: none !important;
}

.el-menu .el-menu-item:hover {
  outline: 0 !important;
  /* color: #2E95FB !important; */
  background: rgb(18, 48, 94) !important;
}

.el-menu .el-menu-item.is-active {
  /* color: #2E95FB !important; */
  background: rgb(18, 48, 94) !important;
}

.el-submenu .el-submenu__title:hover {
  /* color: #2E95FB !important; */
  background: rgb(18, 48, 94) !important;
}
</style>
